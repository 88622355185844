<div>
  <button kendoButton (click)="back()" icon="chevron-left">All Workers</button>
  <button kendoButton (click)="print()" [primary]="true" style="float: right" icon="print">Print</button>
</div>
<div style="display: flex; justify-self: center; flex-direction: column;">
  <ng-container *ngFor="let workers of workersArr; let i = index">
    <div id="print-layout-{{i}}">
      <div fxLayout="column" fxLayoutAlign="space-between center" class="row" style="display: flex; width: 21cm; height: 29.7cm; padding: 25px 25px;">
        <ng-container *ngFor="let worker of workers">
          <div class="col" style="display: flex">
            <div style="width: 360px; height: 180px">
              <h3 style="text-align: center">{{worker.firstName}} {{worker.lastName}}</h3>
              <div class="col" style="display: flex">
                <img [src]="worker.path" style="width: 120px; height: 140px">
                <div style="margin-left: 30px">
                  <div class="col" style="display: flex; margin-top: 4px; margin-bottom: 4px;">
                    <div style="width: 60px; font-size: 10px; margin-right: 10px; padding: 3px">Code</div>
                    <div style="font-weight: bold; font-size: 14px">{{worker.code}}</div>
                  </div>
                  <div class="col" style="display: flex; margin-bottom: 4px;">
                    <div style="width: 60px; font-size: 10px; margin-right: 10px; padding: 3px">Gender</div>
                    <div style="font-weight: bold; font-size: 14px">{{worker.genderText}}</div>
                  </div>
                  <div class="col" style="display: flex; margin-bottom: 4px;">
                    <div style="width: 60px; font-size: 10px; margin-right: 10px; padding: 3px">Unit Fa...</div>
                    <div style="font-weight: bold; font-size: 14px">{{worker.unitFarmName}}</div>
                  </div>
                  <div class="col" style="display: flex; margin-bottom: 4px;">
                    <div style="width: 60px; font-size: 10px; margin-right: 10px; padding: 3px">Emp. T...</div>
                    <div style="font-weight: bold; font-size: 14px">{{worker.workerTypeName}}</div>
                  </div>
                  <div class="col" style="display: flex; margin-bottom: 4px;">
                    <div style="width: 60px; font-size: 10px; margin-right: 10px; padding: 3px">Camp</div>
                    <div style="font-weight: bold; font-size: 14px">{{worker.campName}}</div>
                  </div>
                  <div class="col" style="display: flex;">
                    <div style="width: 60px; font-size: 10px; margin-right: 10px; padding: 3px">City</div>
                    <div style="font-weight: bold; font-size: 14px">{{worker.city}}</div>
                  </div>
                  <img src="/assets/media/images/logo_friel.png" style="width: 100px; margin-left: 65px; margin-top: 10px">
                </div>
              </div>
            </div>
            <div style="padding-left: 180px; padding-top: 50px; padding-right: 80px">
              <qrcode [elementType]="'url'" [qrdata]="generateQRCode(worker)" [width]=160 [errorCorrectionLevel]="'M'"></qrcode>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
