<div class="profile-page main-content" fxLayout="column" fxLayoutAlign="top center">
  <div class="button-bar" style="max-width: 1024px; width:90vw;">
    <button *ngIf="!supervisor && workerDb.status.value === 1" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('list')">All workers</button>
    <button *ngIf="!supervisor && workerDb.status.value === 0" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('deletedList')">All deleted workers</button>
    <button *ngIf="supervisor" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('supervisorDashboard')">Dashboard of supervisor <b>{{supervisor.firstName}} {{supervisor.lastName}}</b></button>&nbsp;
    <button *ngIf="pageName!=='insert' && workerDb" kendoButton themeColor="primary" fillMode="outline" icon="chevron-left" (click)="goToButtonOnClick('dashboard')">Dashboard of worker <b><span *ngIf="workerDb">{{workerDb.firstName.value}} {{workerDb.lastName.value}}</span></b></button>
  </div>
  <div class="card-container" fxLayout="column" fxLayoutAlign="top center">
      <kendo-card style="max-width: 1024px; width:90vw; padding:30px;">
          <form class="k-form k-form-horizontal" [formGroup]="formGroup">
              <fieldset class="k-form-fieldset">
                  <div class="form-title">
                      <h2>{{mainTitle}}&nbsp;</h2>
                  </div>
                  <div class="row form-section">
                    <div class="row from-row">
                      <div class="col-12 section-title no-border">
                          <h3>Personal data</h3>
                      </div>
                    </div>
                    <ng-container *ngIf="workerDb">
                      <div class="row form-row" *ngIf="workerDb.path.value">
                        <div class="picture-wrapper">
                          <img [src]="workerDb.path.value" [alt]="workerDb.lastName.value + ' ' + workerDb.firstName.value" (click)="openCloseImageDialog(true)">
                        </div>
                      </div>
                    </ng-container>
                    <div class="row form-row">
                        <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                                <kendo-label class="k-label" [for]="firstName" [text]="'First name'"> </kendo-label>
                                <kendo-textbox formControlName="firstName" [clearButton]="true" #firstName> </kendo-textbox>
                                <kendo-formerror *ngIf="formGroup.controls['firstName'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                                <kendo-formerror *ngIf="formGroup.controls['firstName'].hasError('maxlength')"> <i> Error: The max allowed length is {{ workerDb.firstName.maxLength }} characters </i></kendo-formerror>
                            </kendo-formfield>
                        </div>
                        <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                                <kendo-label class="k-label" [for]="lastName" [text]="'Last name'"> </kendo-label>
                                <kendo-textbox formControlName="lastName" [clearButton]="true" #lastName> </kendo-textbox>
                                <kendo-formerror *ngIf="formGroup.controls['lastName'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                                <kendo-formerror *ngIf="formGroup.controls['lastName'].hasError('maxlength')"> <i> Error: The max allowed length is {{ workerDb.lastName.maxLength }} characters </i></kendo-formerror>
                            </kendo-formfield>
                        </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="motherName" [text]="'Mother name'"> </kendo-label>
                              <kendo-textbox formControlName="motherName" [clearButton]="true" #motherName> </kendo-textbox>
                              <kendo-formerror *ngIf="formGroup.controls['motherName'].hasError('maxlength')"> <i> Error: The max allowed length is {{ workerDb.motherName.maxLength }} characters </i></kendo-formerror>
                          </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                            <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="grandfatherName" [text]="'Grandfather name'"> </kendo-label>
                              <kendo-textbox formControlName="grandfatherName" [clearButton]="true" #grandfatherName> </kendo-textbox>
                              <kendo-formerror *ngIf="formGroup.controls['grandfatherName'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                              <kendo-formerror *ngIf="formGroup.controls['grandfatherName'].hasError('maxlength')"> <i> Error: The max allowed length is {{ workerDb.motherName.maxLength }} characters </i></kendo-formerror>
                          </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="address" [text]="'Address'"> </kendo-label>
                              <kendo-textbox formControlName="address" [clearButton]="true" #address> </kendo-textbox>
                              <kendo-formerror *ngIf="formGroup.controls['address'].hasError('maxlength')"> <i> Error: The max allowed length is {{ workerDb.address.maxLength }} characters </i></kendo-formerror>
                          </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="city" [text]="'City'"> </kendo-label>
                            <kendo-textbox formControlName="city" [clearButton]="true" #city> </kendo-textbox>
                            <kendo-formerror *ngIf="formGroup.controls['city'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                            <kendo-formerror *ngIf="formGroup.controls['city'].hasError('maxlength')"> <i> Error: The max allowed length is {{ workerDb.city.maxLength }} characters </i></kendo-formerror>
                        </kendo-formfield>
                    </div>
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                          <kendo-label class="k-label" [for]="code" [text]="'Code'"> </kendo-label>
                          <kendo-textbox formControlName="code" [clearButton]="true" #code> </kendo-textbox>
                          <kendo-formerror *ngIf="formGroup.controls['code'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                          <kendo-formerror *ngIf="formGroup.controls['code'].hasError('maxlength')"> <i> Error: The max allowed length is {{ workerDb.code.maxLength }} characters </i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                              <kendo-label class="k-label" [for]="email" [text]="'E-mail'"> </kendo-label>
                              <kendo-textbox formControlName="email" [clearButton]="true" #email> </kendo-textbox>
                              <kendo-formerror *ngIf="formGroup.controls['email'].hasError('maxlength')"> <i> Error: The max allowed length is {{ workerDb.firstName.maxLength }} characters </i></kendo-formerror>
                              <kendo-formerror *ngIf="formGroup.controls['email'].hasError('email')"> <i>Error: Please enter a valid email</i></kendo-formerror>
                          </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="mobile" [text]="'Mobile'"> </kendo-label>
                            <kendo-textbox formControlName="mobile" [clearButton]="true" #mobile> </kendo-textbox>
                            <kendo-formerror *ngIf="formGroup.controls['mobile'].hasError('maxlength')"> <i> Error: The max allowed length is {{ workerDb.mobile.maxLength }} characters </i></kendo-formerror>
                          </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                          <kendo-formfield orientation="horizontal">
                            <label class="k-label"> Gender </label>
                            <ul class="k-radio-list k-list-horizontal">
                                <li class="k-radio-item">
                                    <input type="radio" #genderMale [value]="1" kendoRadioButton formControlName="gender"/>
                                    <kendo-label class="k-radio-label" [for]="genderMale" text="Male"> </kendo-label>
                                </li>
                                <li class="k-radio-item">
                                    <input type="radio" #genderFemale [value]="2" kendoRadioButton formControlName="gender"/>
                                    <kendo-label class="k-radio-label" [for]="genderFemale" text="Female"> </kendo-label>
                                </li>
                            </ul>
                          </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="dateOfBirth" [text]="'Date of birth'"> </kendo-label>
                            <kendo-datepicker formControlName="dateOfBirth" format="dd-MM-yyyy" #dateOfBirth> </kendo-datepicker>
                            <kendo-formerror *ngIf="formGroup.controls['dateOfBirth'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                  </div>
                  <div class="form-section">
                    <div class="row from-row">
                      <div class="col-12 section-title">
                          <h3>Employment data</h3>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="workerTypeId" [text]="'Worker type'"> </kendo-label>
                            <kendo-combobox formControlName="workerTypeId" [data]="workerTypesArray" textField="text" valueField="value" [filterable]="true" (filterChange)="handleWorkerTypeFilter($event)" placeholder="Worker type" #workerTypeId></kendo-combobox>
                            <kendo-formerror *ngIf="formGroup.controls['workerTypeId'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="jobTitle" [text]="'Job title'"> </kendo-label>
                            <kendo-textbox formControlName="jobTitle" [clearButton]="true" #jobTitle> </kendo-textbox>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="unitFarmId" [text]="'Unit farm'"> </kendo-label>
                            <kendo-combobox formControlName="unitFarmId" [data]="unitFarmsArray" textField="name" valueField="id" [filterable]="true" (filterChange)="handleUnitFarmFilter($event)" placeholder="Unit farm" #unitFarmId></kendo-combobox>
                            <kendo-formerror *ngIf="formGroup.controls['unitFarmId'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="campId" [text]="'Camp'"> </kendo-label>
                            <kendo-combobox formControlName="campId" [data]="campsArray" textField="name" valueField="id" [filterable]="true" (filterChange)="handleCampFilter($event)" placeholder="Camp" #campId></kendo-combobox>
                            <kendo-formerror *ngIf="formGroup.controls['campId'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="supervisorId" [text]="'Supervisor'"> </kendo-label>
                            <kendo-combobox formControlName="supervisorId" [data]="supervisorsArray" textField="name" valueField="id" [filterable]="true" (filterChange)="handleSupervisorFilter($event)" placeholder="Supervisor" #supervisorId></kendo-combobox>
                            <kendo-formerror *ngIf="formGroup.controls['supervisorId'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                          <kendo-label class="k-label" [for]="agencyName" [text]="'Agency name'"> </kendo-label>
                          <kendo-textbox formControlName="agencyName" [clearButton]="true" #agencyName> </kendo-textbox>
                        </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                          <kendo-label class="k-label" [for]="issueDate" [text]="'Issue date'"> </kendo-label>
                          <kendo-datepicker formControlName="issueDate" format="dd-MM-yyyy" #issueDate> </kendo-datepicker>
                      </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="employmentDate" [text]="'Employment date'"> </kendo-label>
                            <kendo-datepicker formControlName="employmentDate" format="dd-MM-yyyy" #employmentDate> </kendo-datepicker>
                            <kendo-formerror *ngIf="formGroup.controls['employmentDate'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="expiryDate" [text]="'Expiry date'"> </kendo-label>
                            <kendo-datepicker formControlName="expiryDate" format="dd-MM-yyyy" #expiryDate> </kendo-datepicker>
                            <kendo-formerror *ngIf="formGroup.controls['expiryDate'].hasError('required')"> <i> Error: This field is required </i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                  </div>
                  <div class="buttons-section">
                    <div class="col" style="display: flex">
                      <div class="col-12 align-left" style="display: flex">
                        <qrcode [qrdata]="qrCodeData" [width]="128" [errorCorrectionLevel]="'M'"></qrcode>
                        <button kendoButton style="height: 35px" (click)="loadLayoutPage()">Layout</button>
                      </div>
                        <div class="col-12 align right">
                            <button kendoButton (click)="cancelChanges()">
                                Reset
                            </button>
                            <button kendoButton [primary]="true" (click)="saveChanges()">
                                Save
                            </button>
                        </div>
                    </div>
                  </div>
              </fieldset>
          </form>
      </kendo-card>
  </div>
</div>
<kendo-dialog
  [title]="workerDb.lastName.value + ' ' + workerDb.firstName.value"
  *ngIf="imageDialogOpened"
  (close)="openCloseImageDialog(false)"
  [maxHeight]="'100%'"
  [maxWidth]="'100vw'"
>
  <div [style.background]="'url(' + workerDb.path.value + ') no-repeat top center'" style="background-size: contain; width:60vh; height:80vh;">&nbsp;</div>
</kendo-dialog>
