import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { CropManagerDetailComponent } from './modules/crop-managers/crop-manager-detail/crop-manager-detail.component';
import { CropManagerEditComponent } from './modules/crop-managers/crop-manager-edit/crop-manager-edit.component';
import { CropManagersListComponent } from './modules/crop-managers/crop-managers-list/crop-managers-list.component';
import { GeneralManagerDetailComponent } from './modules/general-managers/general-manager-detail/general-manager-detail.component';
import { GeneralManagerEditComponent } from './modules/general-managers/general-manager-edit/general-manager-edit.component';
import { GeneralManagersListComponent } from './modules/general-managers/general-managers-list/general-managers-list.component';
import { ComparePicturesReportComponent } from './modules/reports/compare-pictures-report/compare-pictures-report.component';
import { CostOfCropReportComponent } from './modules/reports/cost-of-crop-report/cost-of-crop-report.component';
import { DailyHoursComponent } from './modules/reports/daily-hours/daily-hours.component';
import { WorkersAtCropReportComponent } from './modules/reports/workers-at-crop-report/workers-at-crop-report.component';
import { WorkersSalaryReportComponent } from './modules/reports/workers-salary-report/workers-salary-report.component';
import { SupervisorDetailComponent } from './modules/supervisors/supervisor-detail/supervisor-detail.component';
import { SupervisorEditComponent } from './modules/supervisors/supervisor-edit/supervisor-edit.component';
import { SupervisorsListComponent } from './modules/supervisors/supervisors-list/supervisors-list.component';
import { UserEditComponent } from './modules/users/user-edit/user-edit.component';
import { WorkerDetailAssignmentsupervisorsComponent } from './modules/workers/worker-detail/worker-detail-assignmentsupervisors/worker-detail-assignmentsupervisors.component';
import { WorkerDetailComparePicturesComponent } from './modules/workers/worker-detail/worker-detail-compare-pictures/worker-detail-compare-pictures.component';
import { WorkerDetailComponent } from './modules/workers/worker-detail/worker-detail.component';
import { WorkerEditComponent } from './modules/workers/worker-edit/worker-edit.component';
import { WorkersListComponent } from './modules/workers/workers-list/workers-list.component';
import { AuthGuard } from './shared/helpers/auth.guard';
import { WorkersDeletedListComponent } from "./modules/workers/workers-deleted-list/workers-deleted-list.component";
import { WorkersLayoutComponent } from './modules/workers/workers-layout/workers-layout.component';

const routes: Routes = [
  {
    path: '', component: AppLayoutComponent, canActivate: [AuthGuard], children: [
      { path: '', redirectTo: '/main', pathMatch: 'full' },
      { path: 'main', component: MainComponent },
      { path: 'staff/generalManagers/list', component: GeneralManagersListComponent },
      { path: 'staff/generalManagers/dashboard', component: GeneralManagerDetailComponent },
      { path: 'staff/generalManagers/update', component: GeneralManagerEditComponent },
      { path: 'staff/generalManagers/insert', component: GeneralManagerEditComponent },
      { path: 'staff/cropManagers/list', component: CropManagersListComponent },
      { path: 'staff/cropManagers/dashboard', component: CropManagerDetailComponent },
      { path: 'staff/cropManagers/update', component: CropManagerEditComponent },
      { path: 'staff/cropManagers/insert', component: CropManagerEditComponent },
      { path: 'staff/supervisors/list', component: SupervisorsListComponent },
      { path: 'staff/supervisors/dashboard', component: SupervisorDetailComponent },
      { path: 'staff/supervisors/update', component: SupervisorEditComponent },
      { path: 'staff/supervisors/insert', component: SupervisorEditComponent },
      { path: 'staff/workers/list', component: WorkersListComponent },
      { path: 'staff/workers/deleted', component: WorkersDeletedListComponent },
      { path: 'staff/workers/dashboard', component: WorkerDetailComponent },
      { path: 'staff/workers/assignmentssupervisors', component: WorkerDetailAssignmentsupervisorsComponent },
      { path: 'staff/workers/picturecompare', component: WorkerDetailComparePicturesComponent },
      { path: 'staff/workers/update', component: WorkerEditComponent },
      { path: 'staff/workers/insert', component: WorkerEditComponent },
      { path: 'staff/workers/layout', component: WorkersLayoutComponent},
      { path: 'settings/user/profile', component: UserEditComponent },
      { path: 'reports/workersAtCrop', component: WorkersAtCropReportComponent },
      { path: 'reports/costOfCrop', component: CostOfCropReportComponent },
      { path: 'reports/workerSalary', component: WorkersSalaryReportComponent },
      { path: 'reports/dailyHours', component: DailyHoursComponent },
      { path: 'reports/comparePictures', component: ComparePicturesReportComponent }
    ]
  },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
