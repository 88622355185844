<kendo-grid
  [data]="gridData"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [sortable]="sortingSettings"
  [pageable]="{
    info: pagingSettings.info,
    type: pagingSettings.pagerTypes,
    pageSizes: pagingSettings.pageSizes,
    previousNext: pagingSettings.previousNext,
    position: pagingSettings.position,
    responsive: pagingSettings.responsive
  }"
  [reorderable]="!hiddenForMobile"
  [resizable]="!hiddenForMobile"
  filterable="menu"
  [columnMenu]="{ filter: true }"
  (dataStateChange)="dataStateChange($event)"
  style="max-height: 800px; margin-top: 10px !important"
  [selectable]="{ enabled: true, checkboxOnly: true }"
  [rowSelected]="isRowSelected"
  (selectionChange)="onCheckboxClick($event)"
>
  <ng-template kendoGridToolbarTemplate class="grid-toolbar">
    <div style="display:flex; width:100%;">
      <div class="maintitle"><h2 style="margin-top:0px">Workers</h2></div>
      <kendo-grid-spacer></kendo-grid-spacer>
      <div class="commands-bar">
        <button kendoButton [primary]="true" [disabled]="!layoutButtonEnabled" style="margin-right: 10px" (click)="loadLayoutPage()">Layout</button>
        <div class="search-bar" style="display:inline-block; width:300px;">
          <input
            [style.width.px]="300"
            placeholder="Search in all columns..."
            kendoTextBox
            (input)="onFilter($event)"
          />
        </div>
        &nbsp;
        <button kendoButton [primary]="true" icon="plus-circle" (click)="onInsertButtonClick()">Insert</button
        >
        <button kendoGridExcelCommand type="button" icon="file-excel" *ngIf="!hiddenForMobile">
          Export Excel</button
        >
      </div>
    </div>
  </ng-template>
  <kendo-grid-column *ngIf="hiddenForMobile"
    title="Worker"
    [columnMenu]="false"
    [headerClass]="'gridcolumn header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span class="responsive-row"><b>{{dataItem.firstName}} {{dataItem.lastName}}</b></span>
        <span class="responsive-row"><b>Code:</b> {{dataItem.code}}</span>
        <span class="responsive-row"><b>Gender:</b> {{dataItem.genderText}}</span>
        <span class="responsive-row"><b>City:</b> {{dataItem.city}}</span>
        <span class="responsive-row"><b>Worker type:</b> {{dataItem.workerTypeName}}</span>
        <span class="responsive-row"><b>Unit farm:</b> {{dataItem.unitFarmName}}</span>
        <span class="responsive-row"><b>Employment date:</b> {{dataItem.employmentDate}}</span>
        <span class="responsive-row"><b>Expiry date:</b> {{dataItem.expiryDate}}</span>
        <div class="responsive-buttonrow">
          <kendo-splitbutton
            [data]="splitButtonItems"
            (itemClick)="onSplitButtonItemClick($event, dataItem)">
            Actions
          </kendo-splitbutton>
        </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-checkbox-column
    title=""
    [width]="50"
    [showSelectAll]="true"
    [columnMenu]="false"
    [class]="{ 'text-center': true }"
    [headerClass]="{ 'text-center': true }"
    [style]="{'text-align': 'center'}">
  </kendo-grid-checkbox-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="code"
    title="Code"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
</kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="firstName"
    title="First Name"
    filter="text"
    [width]="fixedWidth"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="lastName"
    title="Last Name"
    filter="text"
    [width]="fixedWidth"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="genderText"
    title="Gender"
    filter="text"
    [headerClass]="'gridcolumn header'"
    [hidden]="true"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="city"
    title="City"
    filter="text"
    [headerClass]="'gridcolumn header'"
    [hidden]="true"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="workerTypeName"
    title="Worker Type"
    filter="text"
    [headerClass]="'gridcolumn header'"
    [hidden]="true"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="unitFarmName"
    title="Unit farm"
    filter="text"
    [headerClass]="'gridcolumn header'"
    [hidden]="true"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="supervisorFullName"
    title="Supervisor"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="employmentDate"
    title="Employment date"
    filter="date"
    [headerClass]="'gridcolumn header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.employmentDate | date: 'dd-MM-yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="expiryDate"
    title="Expiry date"
    filter="date"
    [headerClass]="'gridcolumn header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.expiryDate | date: 'dd-MM-yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="createdDate"
    title="Creation date"
    filter="date"
    [headerClass]="'gridcolumn header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.createdDate | date: 'dd-MM-yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    [headerClass]="{ 'text-center': true }"
    [class]="{ 'text-center': true }"
    [width]="150"
    [columnMenu]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <kendo-splitbutton
        [data]="splitButtonItems"
        (itemClick)="onSplitButtonItemClick($event, dataItem)"
      >
        Actions
      </kendo-splitbutton>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel fileName="Workers.xlsx" [fetchData]="excelExportData">
  </kendo-grid-excel>
</kendo-grid>
<kendo-dialog
  title="Are you sure you want to cancel the record?"
  *ngIf="dialogConfirmDeleteActive"
  (close)="dialogConfirmDeleteHandler('no')"
  [minWidth]="250"
  [width]="600"
>
  <div>
    Reason:
  </div>
  <div style="padding-bottom: 10px">
    <kendo-textarea [cols]="70" [rows]="5" placeholder="Enter reason for deletion" [(ngModel)]="_currentWorker.deletedReason">
    </kendo-textarea>
  </div>
  <p style="margin: 30px; text-align: center;">
    Are you sure you want to cancel the record?
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="dialogConfirmDeleteHandler('no')">
      No
    </button>
    <button kendoButton (click)="dialogConfirmDeleteHandler('yes')" themeColor="secondary">
      Yes
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
