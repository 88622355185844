import { LOCALE_ID, NgModule } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MainComponent } from './main/main.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { MainToolbarComponent } from './app-layout/main-toolbar/main-toolbar.component';
import { MessageService } from '@progress/kendo-angular-l10n';
import { CustomMessagesService } from './shared/custom-messages.service';
import { MainFooterComponent } from './app-layout/main-footer/main-footer.component';
import { UsersListComponent } from './modules/users/users-list/users-list.component';
import { UserEditComponent } from './modules/users/user-edit/user-edit.component';
import { CropManagerDetailDataComponent } from './modules/crop-managers/crop-manager-detail/crop-manager-detail-data/crop-manager-detail-data.component';
import { SupervisorDetailComponent } from './modules/supervisors/supervisor-detail/supervisor-detail.component';
import { SupervisorEditComponent } from './modules/supervisors/supervisor-edit/supervisor-edit.component';
import { SupervisorsListComponent } from './modules/supervisors/supervisors-list/supervisors-list.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { UserDetailComponent } from './modules/users/user-detail/user-detail.component';
import { GeneralManagerDetailComponent } from './modules/general-managers/general-manager-detail/general-manager-detail.component';
import { GeneralManagerEditComponent } from './modules/general-managers/general-manager-edit/general-manager-edit.component';
import { GeneralManagersListComponent } from './modules/general-managers/general-managers-list/general-managers-list.component';
import { GeneralManagerDetailDataComponent } from './modules/general-managers/general-manager-detail/general-manager-detail-data/general-manager-detail-data.component';
import { GeneralManagerDetailRelatedComponent } from './modules/general-managers/general-manager-detail/general-manager-detail-related/general-manager-detail-related.component';
import { CropManagerDetailComponent } from './modules/crop-managers/crop-manager-detail/crop-manager-detail.component';
import { CropManagerEditComponent } from './modules/crop-managers/crop-manager-edit/crop-manager-edit.component';
import { CropManagersListComponent } from './modules/crop-managers/crop-managers-list/crop-managers-list.component';
import { CropManagerDetailRelatedComponent } from './modules/crop-managers/crop-manager-detail/crop-manager-detail-related/crop-manager-detail-related.component';
import { SupervisorDetailDataComponent } from './modules/supervisors/supervisor-detail/supervisor-detail-data/supervisor-detail-data.component';
import { SupervisorDetailRelatedComponent } from './modules/supervisors/supervisor-detail/supervisor-detail-related/supervisor-detail-related.component';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { TypographyModule } from '@progress/kendo-angular-typography';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialDesignModule } from './material-design.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WorkerDetailComponent } from './modules/workers/worker-detail/worker-detail.component';
import { WorkersListComponent } from './modules/workers/workers-list/workers-list.component';
import { WorkerEditComponent } from './modules/workers/worker-edit/worker-edit.component';
import { WorkerDetailDataComponent } from './modules/workers/worker-detail/worker-detail-data/worker-detail-data.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { WorkerDetailPictureComponent } from './modules/workers/worker-detail/worker-detail-picture/worker-detail-picture.component';
import { WorkerDetailRelatedsupervisorsComponent } from './modules/workers/worker-detail/worker-detail-relatedsupervisors/worker-detail-relatedsupervisors.component';
import { WorkerDetailAssignmentsupervisorsComponent } from './modules/workers/worker-detail/worker-detail-assignmentsupervisors/worker-detail-assignmentsupervisors.component';
import { WorkersAtCropReportComponent } from './modules/reports/workers-at-crop-report/workers-at-crop-report.component';
import { ChartsModule } from "@progress/kendo-angular-charts";
import { CostOfCropReportComponent } from './modules/reports/cost-of-crop-report/cost-of-crop-report.component';
import { WorkersSalaryReportComponent } from './modules/reports/workers-salary-report/workers-salary-report.component';
import { WorkerDetailComparePicturesComponent } from './modules/workers/worker-detail/worker-detail-compare-pictures/worker-detail-compare-pictures.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { DailyHoursComponent } from './modules/reports/daily-hours/daily-hours.component';
import { WorkerLocationMapComponent } from './modules/workers/worker-detail/worker-detail-compare-pictures/worker-location-map/worker-location-map.component';
import { ComparePicturesReportComponent } from './modules/reports/compare-pictures-report/compare-pictures-report.component';
import { ComparePicturesDialogComponent } from "./modules/reports/compare-pictures-report/compare-pictures-dialog/compare-pictures-dialog.component";
import { WorkersDeletedListComponent } from "./modules/workers/workers-deleted-list/workers-deleted-list.component";
import { UserDetailRelatedComponent } from "./modules/users/user-detail/user-detail-related/user-detail-related.component";
import { UserDetailDataComponent } from "./modules/users/user-detail/user-detail-data/user-detail-data.component";
import { QRCodeModule } from 'angularx-qrcode';
import { WorkersLayoutComponent } from './modules/workers/workers-layout/workers-layout.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoadingSpinnerComponent,
    MainComponent,
    AppLayoutComponent,
    MainToolbarComponent,
    MainFooterComponent,
    UsersListComponent,
    UserEditComponent,
    UserDetailComponent,
    UserDetailDataComponent,
    UserDetailRelatedComponent,
    GeneralManagerDetailComponent,
    GeneralManagerEditComponent,
    GeneralManagersListComponent,
    GeneralManagerDetailDataComponent,
    GeneralManagerDetailRelatedComponent,
    CropManagerDetailComponent,
    CropManagerEditComponent,
    CropManagersListComponent,
    CropManagerDetailDataComponent,
    CropManagerDetailRelatedComponent,
    SupervisorDetailComponent,
    SupervisorEditComponent,
    SupervisorsListComponent,
    SupervisorDetailDataComponent,
    SupervisorDetailRelatedComponent,
    WorkerDetailComponent,
    WorkersListComponent,
    WorkersDeletedListComponent,
    WorkerEditComponent,
    WorkerDetailDataComponent,
    WorkerDetailPictureComponent,
    WorkerDetailRelatedsupervisorsComponent,
    WorkerDetailAssignmentsupervisorsComponent,
    WorkersAtCropReportComponent,
    CostOfCropReportComponent,
    WorkersSalaryReportComponent,
    WorkerDetailComparePicturesComponent,
    DailyHoursComponent,
    WorkerLocationMapComponent,
    ComparePicturesReportComponent,
    ComparePicturesDialogComponent,
    WorkersLayoutComponent,
  ],
  imports: [
    TypographyModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialDesignModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    GridModule,
    PDFModule,
    ExcelModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    ButtonsModule,
    IndicatorsModule,
    NotificationModule,
    DateInputsModule,
    DropDownsModule,
    FlexLayoutModule,
    DialogsModule,
    ChartsModule,
    LeafletModule,
    QRCodeModule
    /**/
  ],
  providers: [
    { provide: MessageService, useClass: CustomMessagesService },
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
